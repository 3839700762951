import React from 'react'
import { CssBaseline, Box } from '@material-ui/core'
import Header from './Header'

interface LayoutProps {
  children: React.ReactNode
  location?: Location
  noHeader?: boolean
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  noHeader,
}) => {
  return (
    <>
      <CssBaseline />
      <Box id="Top">
        <Header />
        {children}
      </Box>
    </>
  )
}

export default Layout
