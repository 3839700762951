import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, image, meta, keywords, title, pathname }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.sanitySiteSettings.description
        const metaImage =
          image && image.src
            ? `${data.sanitySiteSettings.siteUrl}${image.src}`
            : null

        const metaUrl = `${data.sanitySiteSettings.siteUrl}${pathname}`
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.sanitySiteSettings.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              //   {
              //     name: `twitter:creator`,
              //     content: `@${data.site.siteMetadata.social.twitter}`,
              //   },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              //   {
              //     name: 'google-site-verification',
              //     content: 'QlRmuLQWttdkbKlZ0ZwIBX3xv0M8ouqTW3wE2Eg_jKI',
              //   },
            ]
              .concat(
                metaImage
                  ? [
                      {
                        property: `og:image`,
                        content: metaImage,
                      },
                      {
                        property: `og:image:alt`,
                        content: title,
                      },
                      {
                        property: 'og:image:width',
                        content: image.width,
                      },
                      {
                        property: 'og:image:height',
                        content: image.height,
                      },
                      {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                      },
                    ]
                  : [
                      {
                        property: `og:image`,
                        content:
                          data.sanitySiteSettings.seoimage.asset.fixed.src,
                      },
                      {
                        property: `og:image:alt`,
                        content: title,
                      },
                      {
                        property: 'og:image:width',
                        content:
                          data.sanitySiteSettings.seoimage.asset.metadata
                            .dimensions.width,
                      },
                      {
                        property: 'og:image:height',
                        content:
                          data.sanitySiteSettings.seoimage.asset.metadata
                            .dimensions.height,
                      },
                      {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                      },
                    ]
              )
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
  keywords: [],
  pathname: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.object,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      buildTime(formatString: "DD-MM-YYYY")
    }
    sanitySiteSettings {
      seoimage {
        asset {
          fixed(width: 1260) {
            src
          }
          metadata {
            dimensions {
              height
              width
            }
          }
        }
      }
      socials {
        instagram
        twitter
        facebook
      }
      title
      description
      author
      logo {
        asset {
          url
        }
      }
      siteHeadline
      siteLanguage
      siteUrl
      titleAlt
      ogLanguage
    }
  }
`
