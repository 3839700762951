/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'

const useHideOnScroll = () => {
  const prevScrollY = React.useRef<number>()
  const [isHidden, setIsHidden] = React.useState(false)
  const [isTop, setIsTop] = React.useState(false)

  React.useEffect(() => {
    const onScroll = () => {
      setIsHidden((isHidden) => {
        const scrolledDown = window.scrollY > prevScrollY.current!
        if (scrolledDown && !isHidden) {
          return true
        } else if (!scrolledDown && isHidden) {
          return false
        } else {
          prevScrollY.current = window.scrollY
          return isHidden
        }
      })

      setIsTop(window.scrollY !== 0)
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll)
      return () => {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  return [isHidden, isTop]
}
export default useHideOnScroll
