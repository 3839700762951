import React from 'react'
import {
  Toolbar,
  makeStyles,
  Container,
  Box,
  fade,
  Button,
  Hidden,
  List,
  ListItemText,
  ListItem,
  SwipeableDrawer,
} from '@material-ui/core'
import { Link } from 'gatsby-theme-material-ui'
import PhoneIcon from '@material-ui/icons/Phone'
import Logo from './Logo'
import { navigate } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  logo: {
    position: 'fixed',
  },
  nav: {
    '& a:not(:first-child)': { marginLeft: theme.spacing(4) },
    '& a': {
      cursor: 'pointer',
      '&:hover': {
        textDecorationLine: 'none',
      },
    },
  },
  header: {
    width: '100%',
    position: 'fixed',
    display: 'flex',
    zIndex: 10,
    top: 0,
    background: fade('#100e17', 0.9),
  },
  menu: {
    justifyContent: 'flex-end',
  },
  logoSvg: {
    fill:
      theme.palette.type === 'light'
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
  },
  cta: {
    marginLeft: theme.spacing(4),
  },
}))
const ListItemLink = (props) => {
  return <ListItem button component={'a'} {...props} />
}

interface Header {
  className?: string
}
const MenuItems: React.FC = () => {
  return (
    <Box width={'60vw'}>
      <List>
        <ListItemLink href={'/'}>
          <ListItemText primary="Home" />
        </ListItemLink>
        <ListItemLink href={'/behandelingen'}>
          <ListItemText primary="Behandelingen" />
        </ListItemLink>
        <ListItemLink href={'/contact'}>
          <ListItemText primary="Contact" />
        </ListItemLink>
        <ListItem
          variant="contained"
          color="secondary"
          startIcon={<PhoneIcon />}
          component={Button}
          href={'tel:0032499281863'}
        >
          +32 499 28 18 63
        </ListItem>
      </List>
    </Box>
  )
}
const Header: React.FC<Header> = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setOpen(open)
  }

  return (
    <>
      <Box className={classes.header}>
        <Container maxWidth={'lg'}>
          <Box
            component="header"
            position="flex"
            justifyContent="space-between"
          >
            <Logo />
            <Toolbar disableGutters className={classes.menu}>
              <Hidden smDown>
                <nav className={classes.nav}>
                  <Link
                    variant="h6"
                    underline="none"
                    onClick={() => navigate(`/`)}
                    tabIndex={0}
                  >
                    Home
                  </Link>
                  <Link
                    underline="none"
                    onClick={() => navigate(`/behandelingen`)}
                    tabIndex={0}
                    variant="h6"
                  >
                    Behandelingen
                  </Link>
                  <Link
                    onClick={() => navigate(`/contact`)}
                    tabIndex={0}
                    underline="none"
                    variant="h6"
                  >
                    Contact
                  </Link>

                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PhoneIcon />}
                    className={classes.cta}
                    href={'tel:0032499281863'}
                  >
                    +32 499 28 18 63
                  </Button>
                </nav>
              </Hidden>
              <Hidden mdUp>
                <Button onClick={() => setOpen(true)}> Menu </Button>
              </Hidden>
            </Toolbar>
          </Box>
          <SwipeableDrawer
            anchor={'right'}
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <MenuItems />
          </SwipeableDrawer>
        </Container>
      </Box>
    </>
  )
}
export default Header
